import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  userRole: string;
  questionList: [];

  constructor() { }

  ngOnInit(): void {
    //this.questionList.push({question: "How do I get paid as a trader on Stock Pilot?", answer: ""});
  }

  onSelectRole(role: string) {
    this.userRole = role;
  }

}
