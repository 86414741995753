import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';
import { AuthState } from '../common/models/authState';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly _authState = new BehaviorSubject<AuthState>(
    new AuthState()
  );
  readonly _autSignupState = new BehaviorSubject<string>(null);

  readonly auth$ = this._authState.asObservable();

  private path: string | null;

  private role: string;

  constructor(private router: Router,
    private _ngZone: NgZone) {

    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      _err => this._authState.next(new AuthState())
    );




     Hub.listen('auth', ({ payload: { event, data, message }, channel }) => {

      if (channel === 'auth' && event === 'signIn') {

        this._ngZone.run(()=>{
          Auth.currentSession().then(res => {
            let accessToken = res.getIdToken();
            this.role = accessToken.payload['cognito:groups'] && accessToken.payload['cognito:groups'][0] === 'Pilots' ? 'Pilots' : 'Subscriber';
            this.setUser(data);
            if(this.role === 'Pilots') {
              this.router.navigate(['/pilot'])
            } else {
              this.router.navigate(['/dashboard'])
            }
          }).catch(err => {
            console.log(err);
          })
        });
      }
      if (channel === 'auth' && event === 'signOut') {
        this._authState.next(new AuthState());
        localStorage.clear()
        this.router.navigate(['/home']);
      }

      if (channel === 'auth' && event === 'signUp') {
//         this._ngZone.run(()=>{
//           this.router.navigate(['signup'])
//           });
//           this._autSignupState.next('rolSelection')
//         localStorage.setItem('firstTimeUser', JSON.stringify('true'));
      }
    });
  }

private setUser(user: any) {
    if (!user) {
      return;
    }
    let authState = new AuthState();
    authState.isLoggedIn = true;
    authState.username = user.username;
    authState.role = this.role;
    this._authState.next(authState);
  }

  signOut() {
    Auth.signOut();
    this._authState.next(new AuthState());
  }
}
