
  <app-header></app-header>

    <div class="container-fluid main-window" >

        <app-loader></app-loader>

      <router-outlet>
      </router-outlet>
    </div>

  <app-footer></app-footer>
