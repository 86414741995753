<section>
  <div class="row justify-content-center">
    <div class="col">
<!--      <button *ngIf="userRole == 'Subscriber'" class="btn btn-primary float-left" style="margin-right:5%;" (click)="onSelectRole('Pilot')"><i class="fa fa-arrow-left"></i> Pilot FAQs</button>-->
    </div>
    <div class="col-6 justify-content-center">
      <h4 class="text-center" *ngIf="tradeResponse.status == 'OK'">{{tradeResponse.message}}</h4>
      <h4 class="text-center text-secondary" *ngIf="tradeResponse.status == 'ERROR'">{{tradeResponse.message}}</h4>
<!--      <h4 class="text-center" *ngIf="!userRole">Questions About How Stock Pilot Works?</h4>-->
<!--      <h4 class="text-center" *ngIf="userRole == 'Pilot'">Here's some common questions we've heard from interested Pilots..</h4>-->
<!--      <h4 class="text-center" *ngIf="userRole == 'Subscriber'">Here's some common questions we've heard from interested Passengers..</h4>-->

    </div>
    <div class="col">
<!--      <button *ngIf="userRole == 'Pilot'" class="btn btn-primary float-right" style="margin-left:5%;" (click)="onSelectRole('Subscriber')">Subscriber FAQs <i class="fa fa-arrow-right"></i></button>-->
    </div>
  </div>
  <hr>
</section>
