<section class="footer-sec container-space">

    <div class="row align-items-center">

      <div class="col d-flex">

        <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-icon-to-home' data-stockpilot-analytics-attrs='attr1:icon_to_home_clicked'routerLink="/home" routerLinkActive="active"><img src="assets/images/SPLogo.svg"></a>

        <ul class="navbar-nav ml-3">
          <li class="nav-item">
            <!-- <a class="nav-link" href="#">Link</a> -->
            <a class=" text-secondary nav-link login mr-4 font-size-a" routerLink="/contact" routerLinkActive="active">Contact Us</a>
          </li>
        </ul>

      </div>
      <div class="col">
        <p class="mb-0 text-light float-right">Copyright 2021 All Right Reserved By StockPilot</p>



      </div>
    </div>

</section>



