import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export interface Blog {
  num: string;
  link: string;
  img: string;
  title: string;
  snippet: string;
  date: string;
  tag: string;
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {


blogs: Blog[] = [
    {num: '01',link: '', img: '--bg-img: url(\'assets/images/blog/purpose-fed-reserve.png\')', title: 'What is the Federal Reserve?', snippet: 'The Fed is responsible for supervising and regulating in order to ensure that these institutions…', date: '23 Jan 2023', tag: 'Finance'},
    {num: '02',link: '', img: '--bg-img: url(\'assets/images/blog/equity-bond-decouple-vg.png\')',  title: 'Decoupling between Equity and Bond Markets in 2022', snippet: 'Asset classes have expected pattern of correlation with one another…', date: '23 Jan 2023', tag: 'Finance'},
    {num: '03',link: '', img: '--bg-img: url(\'assets/images/blog/401k-vs-pension.png\')', title: '401k vs Pension - what\'s what?', snippet: 'When it comes to employer-sponsored retirement plans…', date: '27 Feb 2023', tag: 'Retirement'}
  ];

  blog: any;
  subscriptionForm;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
      this.subscriptionForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
      })
  }

  setBlog(i: any) {
    this.blog = this.blogs[i];
    console.log('asdf');
  }
}
