<section>
  <div class="row justify-content-center">
    <div class="col">
      <button *ngIf="userRole == 'Subscriber'" class="btn btn-primary float-left" style="margin-right:5%;" (click)="onSelectRole('Pilot')"><i class="fa fa-arrow-left"></i> Pilot FAQs</button>
    </div>
    <div class="col-6 justify-content-center">
      <h4 class="text-center" *ngIf="!userRole">Questions About How Stock Pilot Works?</h4>
      <h4 class="text-center" *ngIf="userRole == 'Pilot'">Here's some common questions we've heard from interested Pilots..</h4>
      <h4 class="text-center" *ngIf="userRole == 'Subscriber'">Here's some common questions we've heard from interested Passengers..</h4>
    </div>
    <div class="col">
      <button *ngIf="userRole == 'Pilot'" class="btn btn-primary float-right" style="margin-left:5%;" (click)="onSelectRole('Subscriber')">Subscriber FAQs <i class="fa fa-arrow-right"></i></button>
    </div>
  </div>
  <hr>
</section>
<br>
<section id="subscibe-sec" class="container-space section-space" *ngIf="!userRole">
  <div>
    <div class="row ">
      <div class="col-sm-6 pr-4" style="border-right:1px solid #383636">
        <h4 class="text-center">Interested in Piloting a Social Fund?</h4>
        <div>
          <p class="text-center">
            How am I ranked? How do I get paid? How can I manage user memberships? Learn more by clicking below.
          </p>
          <div class="text-center mb-4">
            <button class="btn btn-primary" (click)="onSelectRole('Pilot')">Learn more!</button>
          </div>
        </div>
      </div>
      <div class="col-sm-6 pr-4" style="border-right:1px solid #383636">
        <h4 class="text-center">Want to Subscribe to Unique Strategies?</h4>
        <div class="text-center">
          <p>
            What is Stock Pilot? How do I know which Pilot to choose? Learn more below!
          </p>
          <div class="text-center mb-2">
            <button class="btn btn-primary" (click)="onSelectRole('Subscriber')">Learn more!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
</section>
<section *ngIf="userRole == 'Subscriber'">
  <div class="text-center" style="width:80%;margin-left:10%;">

    <div class="accordion" id="strategyfaqacord">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left text-primary" type="button" data-toggle="collapse" data-target="#strategyfaq1">
              What the heck is Stock Pilot anyway?
            </button>
          </h2>
        </div>


        <div id="strategyfaq1" class="collapse show" data-parent="#strategyfaqacord">
          <div class="card-body">

            <p>
              Stock Pilot is a social hedge fund platform that allows individual investors to copy the trades of ranked traders.
            </p>
            <p>What is a social hedge fund? A social hedge fund is generally comprised of an online community of common investors,
              where members meet to discuss the markets and instruments they may currently be trading. The outputs of this type of
              community and discussion is, in properly motivated communities, generally very helpful trade information.
              While none of this trade information is catered to any individual of the group, the general information may prove useful in
              helping community members better grow their money. While traditionally, each member of such a community must enter trade orders
              individually, Stock Pilot serves to eliminate
              this burden by automating the placement of these orders, effectively reducing the trading steps for members
              of the community.</p>

            <p>To take it a step further, and to ensure that none of Stock Pilots users are copying the trades of sub-par Pilots,
              the platform is focused around a quantitative platform ranking system, that serves to assist our passenger-side members in only
              copying the highest quality of traders. In order for a Pilot to be eligible for listing on our public Pilots listing page,
              they must adhere to our minimum trade performance requirements. From there, the performance of a given Pilot community is
              ranked and categorized, giving a relative comparison of all publicly approved Pilots listed on the site.

            </p>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq2">
              Can I approve the trades before they execute in my brokerage account?
            </button>
          </h2>
        </div>
        <div id="strategyfaq2" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Yes! With our SMS trade confirmation feature you can approve or decline a trade as it gets alerted to your phone. But keep in mind you may want to be quick or you might miss the trade.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq3">
              What brokerages are supported by Stock Pilot?
            </button>
          </h2>
        </div>
        <div id="strategyfaq3" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Stock Pilot is in the process of integrating with Tradier and TradeStation brokerages.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq4">
              How do I find the right Pilot to subscribe to?
            </button>
          </h2>
        </div>
        <div id="strategyfaq4" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>*Coming Soon* Our Trader Listing page will allow you to browse our registered Pilots and compare several historical performance metrics to assist propsective members when choosing a community.</p>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq5">
              How am I billed?
            </button>
          </h2>
        </div>
        <div id="strategyfaq5" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>You will be billed monthly through your Pilot or subscribed communities website.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq6">
              What type of brokerage account is needed?
            </button>
          </h2>
        </div>
        <div id="strategyfaq6" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Depending on your brokerage you can use a IRA or Individual account. Also, please check the trader listing page if a cash or margin account is preferred for your subscribed strategy.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq7">
              What level of Options approvals do I need? Will I need a margin account?
            </button>
          </h2>
        </div>
        <div id="strategyfaq7" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Please check with your Pilot community regarding what level of options approval is required to support their specific trading strategies or if they require the use of marginable funds.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq8">
              How do I cancel my subscription?
            </button>
          </h2>
        </div>
        <div id="strategyfaq8" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>You can cancel your subscription at any time through your Pilot’s community website. You can also contact Stock Pilot support support@stockpilot.app to cancel manually.
              <br>
              <br>
              ** We are in the process of building out membership management features on our subscriber side dashbaords. **</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq9">
              What level of control will Stock Pilot have on my account?
            </button>
          </h2>
        </div>
        <div id="strategyfaq9" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Stock Pilot will only be able to place trades and display account balances on your account. You will still maintain full control of your
              account. While it may not be recommended, you can still place trades alongside the automation or even exit trades that were placed through the automation.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq10">
              What type of automation controls am I provided with?
            </button>
          </h2>
        </div>
        <div id="strategyfaq10" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Members are provided with a set of several automation parameters that are used for trading. This includes on/off, strategy level buying power, as well as several position size scaling features. Please view the subscriber side 'How it Works' for more information on how these features work. This is presented when new members first log in, and is always available in the account view located at the top of the subscriber dashboard near the information icon.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq11">
              Can I close a trade that was opened by the trade automation?
            </button>
          </h2>
        </div>
        <div id="strategyfaq11" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Of course. If at any time you feel uncomfortable or need to exit a position, you can log into your account via your brokerage's website to do so.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq13">
              How can I be alerted on the trades my Pilot is trading?
            </button>
          </h2>
        </div>
        <div id="strategyfaq13" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Generalized trading alerts will be published to member communities through one of several connected platforms, including Discord, Telegram, and SMS. Separate from the alerting system, some member communities may be configured to utilize our
              trade confirmation mechanism. With this, members will receive a direct SMS notification providing information around a potential order, which they may approve to allow the Stock Pilot system to place on their behalf.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq14">
              Is there an account minimum requirement?
            </button>
          </h2>
        </div>
        <div id="strategyfaq14" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>While Stock Pilot itself does not enforce an account minimum, many member communities do have account minimums needed to support their specific trading strategy.
             This is often required for proper risk management and thus should be considered when choosing a strategy to subscribe to.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq15">
              What are the associated fees?
            </button>
          </h2>
        </div>
        <div id="strategyfaq15" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Each Pilot sets their own Social Fund fee. You can view these monthly fees through your Pilot's website or our Trade Listing page.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq16">
              Have additional questions?
            </button>
          </h2>
        </div>
        <div id="strategyfaq16" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p><a class="text-secondary" routerLink="/contact" routerLinkActive="active">Contact Us!</a></p>
          </div>
        </div>
      </div>
    </div>



    <!--
        <h5>
          <mat-label class="text-primary mb-2">What the heck is Stock Pilot anyway?</mat-label>
        </h5>
        <br>
        <br>


        <p class="text-white mb-2">
          Stock Pilot is a social hedge fund platform that allows individual investors to copy the trades of ranked traders.
          <br>
          <br>
          What is a social hedge fund? A social hedge fund is generally comprised of an online community of common investors,
          where members meet to discuss the markets and instruments they may currently be trading. The outputs of this type of
          community and discussion is, in properly motivated communities, generally a wealth of positive trade information.
          While none of this trade information is catered to any individual of the group, the general information may prove useful in
          helping community members better grow their money. While traditionally, each member of such a community must enter trade orders
          individually, Stock Pilot serves to eliminate
          this burden by automating the placement of these orders, effectively providing hands-free trading capabilities to members
          of the community.

          <br>
          <br>
          To take it a step further, and to ensure that none of Stock Pilots users are copying the trades of sub-par Pilots,
          the platform is focused around a quantitative platform ranking system, that serves to assist our passenger-side members in only
          copying the highest quality of traders. In order for a Pilot to be eligible for listing on our public Pilots listing page,
          they must adhere to our minimum trade performance requirements. From there, the performance of a given Pilot community is
          ranked and categorized, giving a relative comparison of all publicly approved Pilots listed on the site.


          <br>
          <br>

        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">How can I setup a Stock Pilot autotrading account?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Apart from just a little money to invest, all you need to setup an auto-trading account with Stock Pilot is a brokerage
          account and a Pilot community of which to subscribe. You may open a brokerage account at any one of our supported
          brokerages, as listed here. Likewise, checkout our Flight-Approved Pilots listing page to learn more about our proprietary
          ranking systems, designed to help you make the most informed decisions when choose a Pilot.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Do you have any recommendations as to which Pilot community to subscribe to?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          No. We will never provide Pilot recommendations, nor any form of financial advice. You may browse our Flight-Approved Pilot listing and performance analytics page, which
          is designed to help any prospective Stock Pilot members make the best decisions themselves when choosing a Pilot to subscribe to.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">What type of control does Stock Pilot have of my brokerage account, and will I retain
            full control over my account?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          In order for the Stock Pilot trading bot to submit trades on your behalf, you must grant the Stock Pilot platform full
          access to your brokerage account. This access is facilitated by the industry standard OAuth 2.0 Authorization Code grant type.
          You may revoke access to the Stock Pilot platform at any time by cancelling your Stock Pilot subscription or contacting your
          brokerage directly.
          <br>
          <br>
          While the Stock Pilot application will have full control over your account, you will also retain full control over your account.
          You may log in to your brokerage directly at any time, to place trades, view order history, or even close positions
          previously opened by the trade bot.
          <br>
          <br>
          <i class="text-secondary">While you may login and place or cancel trades made by the auto trade bot, this is NOT recommended. Users subscribing to
          a Pilot do not generally know why a given trade may have been placed or what the expected behavior may be. This is why
          we suggest trusting the Pilot and letting them do what they do best.</i>
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">What types of automation controls are available to me as a Passenger?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          At the core, after subscribing to a given Pilot community, subscribers are able to toggle the trade automation on or off. After this,
          there is an additional override toggle, to which users can override the default settings as provided by their Pilot community. This is
          generally NOT recommended. As subscribers may not generally understand when or why a trader may place a particular order, it is
          likely best to keep the automation override settings toggled off. This allows subscribed accounts to always copy the same
          position sizing as the Pilot, mimicking their risk management parameters, or ensuring a secondary order serving as a hedge to the first
          is properly submitted to properly distribute the risk.
          <br>
          <br>
          If a subscriber, for whatever reason, does choose to override their Pilot's default automation settings, they are provided with
          the following controls over their automation bot: (1) Users may set a dollar or percentage value to be used for each trade, based upon their
          accounts current amount available for trading. (2) They may place a percentage or dollar based stop order to be triggered after
          any trades placed by the auto-trade bot. (3) They may add company tickers to a list of filtered tickers that they do not
          or cannot have traded on their account.
          <br>
        </p>
        <br>
        <br>
        <br> -->
  </div>
</section>
<section *ngIf="userRole == 'Pilot'">
  <div class="text-center" style="width:80%;margin-left:10%;">

    <div class="accordion" id="strategyfaqacord">
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq3">
              What brokerages are supported by Stock Pilot?
            </button>
          </h2>
        </div>
        <div id="strategyfaq3" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Stock Pilot is in the process of integrating with Tradier and TradeStation brokerages.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq4">
              Can I connect Stock Pilot to my social community?
            </button>
          </h2>
        </div>
        <div id="strategyfaq4" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Absolutely, you are able to integrate Stock Pilot to your Discord, Telegram, SMS, and email communities. As trades are placed through
              the system, generalized trading information about the order the Pilot is taking will be published to the community's connected social platforms in our standard alert format. Pilots may also
              publish generalized messages to their communities through their dashboards.</p>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq5">
              How do I submit a trade through the platform?
            </button>
          </h2>
        </div>
        <div id="strategyfaq5" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Our Pilot dashboard supplies you with the tools and order submission forms to submit trades directly to your connected
              brokerage account (optional) as well as all of your subscribers. </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq6">
              How do I manage subscriber memberships?
            </button>
          </h2>
        </div>
        <div id="strategyfaq6" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Memberships are managed through the 'Memberships' tab on your Trader Dashboard. Here is where you are able to generate
              unique links for new subscribers, view subscriber contact information, edit strategies, and delete/cancel subscription plans.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq7">
              How do I get paid?
            </button>
          </h2>
        </div>
        <div id="strategyfaq7" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Traders are able to list and sell their Stock Pilot subscriptions directly on their websites. After a subscriber
              has paid on your website you are able to generate a unique link through the Trader Dashboard under ‘Memberships” that
              will grant the subscriber access to your social fund. Each subscription purchased through the trading club will be invoiced
              at the end of the month. Contact our sales team to learn more.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq9">
              Can I establish multiple trading strategies?
            </button>
          </h2>
        </div>
        <div id="strategyfaq9" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>Absolutely! Many Social Funds have multiple strategists who trade separate strategies. We allow Pilots to bring on as many Co-Pilots as necessary for their
              community to properly run.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq10">
              Is there a limit to community size?
            </button>
          </h2>
        </div>
        <div id="strategyfaq10" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>There is no limit to community size. During new community onboarding, we collect some information around group size and members. With this, we are then able
            to properly provision the required infrastructure to support any number of members. We ensure our support team is available during the onboarding of new and large groups to assist with any questions and
            access link distribution.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq11">
              How many analysts can I have in my community?
            </button>
          </h2>
        </div>
        <div id="strategyfaq11" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>You are not limited by how many Co-Pilots or analysts you have in your community. Each Co-Pilot can have their own separate
              channel for their specific trades and subscribers.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq12">
              What are the supported asset types?
            </button>
          </h2>
        </div>
        <div id="strategyfaq12" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p>You are able to trade equity and option asset types through the Stock Pilot platform. Futures and other assets are coming soon!</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#strategyfaq13">
              Have additional questions?
            </button>
          </h2>
        </div>
        <div id="strategyfaq13" class="collapse" data-parent="#strategyfaqacord">
          <div class="card-body">
            <p><a class="text-secondary" routerLink="/contact" routerLinkActive="active">Contact Us!</a></p>
          </div>
        </div>
      </div>
    </div>

    <!--
        <h5>
          <mat-label class="text-primary mb-2">What the heck is Stock Pilot anyway?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Anybody may publish trades on the Stock Pilot platform. At it's core, there are two major components to the Stock Pilot platform.
          The first is a community signaling system that transmits any messages about what a Pilot may be trading to the subscribed members.
          Since this is more higher-level, general trade information, and not anything close to resembling a financial recommendation
          catered towards one's individual circumstances, publishing this information is protected by the First Amendment.
          <br>
          <br>
          The second is a trade automation bot for use by individual members of the given community. These members may then each
          personally decide if and when they would like to configure their trade bot to copy any of these published trade signals.
          This automation serves to assist the individual investor, by enabling them to realize the benefits of hands-free trading.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">How do I get paid as a trader on Stock Pilot?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Traders on our platform are paid based on their agreed upon trader fee as determined during their initial onboarding interview.
          The fee for the Stock Pilot automation service is always $100 per month. The total fee as advertise for joining a
          specific community consists of this $100, PLUS the traders monthly fee. ( For example, $100 Stock Pilot fee + $100 trader
          fee results in a total monthly fee of $200 per month. )
          <br>
          <br>
          Stock Pilot subscriptions are facilitated using the Stripe platform. During the initial Pilot onboarding process,
          members are asked to create and connect a Stripe account for themselves or organization, to which Stripe will automatically
          route the trader fee portion of the subscription payment directly to their Stripe account.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Do I need any financial certification or registrations to be a trader on Stock Pilot?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          While it may be preferred by potential subscribers, it is not mandatory to have any financial certifications. In the context
          of a social fund, you are well within your rights to openly discuss generalized trading information, so long as members to not
          offer individualized advice catered to someone's unique circumstances. In fact, the freedom to discuss this general trade information
          is protected by the First Amendment of our Constitution.
          <br>
          <br>
        </p>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">How are member subscriptions managed for my social fund?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Member subscriptions on the platform are managed by Stripe. During the initial onboarding process, members are asked to create
          and connect a Stripe account for themselves or organization. When a new member joins your social fund, Stock Pilot will
          create a new customer and subscription plan on the Trader or Pilot's community's Stripe account. This allows full control
          of client memberships to remain at the discretion of the community owner.
          <br>
          <br>
          Pilots and Co-Pilots on Stock Pilot may managed ( create, update, view, or delete ) their current and previous members by
          logging into their connected Stripe account's dashboard.
          <br>
          <br>
          If a current members subscription is cancelled via the Stripe dashboard ( or by the subscriber themselves ), the Stripe
          platform will notify the Stock Pilot platform and schedule the account for member off-boarding. This off-boarding process
          does ensure all positions opened by the autotrade bot are properly closed via the automation before removing the account
          from execution. The account owner may close these positions at any time.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">What brokerages are supported by Stock Pilot?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Stock Pilot currently supports TD Ameritrade, Tradier, and Tradestation brokerage services.
        </p>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Am I able to link to Stock Pilot from my trade communities website?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Yes of course. After successful new Pilot registration, Stock Pilot will provide your community with several unique
          links which you may place and advertise on your web
        </p>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Which input channels does Stock Pilot support?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Trade alert signals may be inputted through a variety of different channels into the Stock Pilot System. Some examples
          include our trader dashboard, Discord, SMS, as well as direct copy from your personal brokerage dashboards.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Does Stock Pilot support communities that are not considered ranked on the platform?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Of course. Traders may establish what we refer to as a private Pilot fund. In this scenario, we simply provide a unique
          URL that Pilots may share with friends, family, or other members. While trade data will still be collected on these traders
          for potential use in a future public listing, this data will remain private until when and if the Pilot agrees they would
          like to be listed publiclly for trade-copy subscribers.
        </p>
        <br>
        <br>
        <br>
        <h5>
          <mat-label class="text-primary mb-2">Can I copy trades on my personal account through Stock Pilot?</mat-label>
        </h5>
        <br>
        <br>
        <p class="text-white mb-2">
          Yes of course. After successful registration, simply navigate to the advanced settings tab of your trader dashboard
          and toggle the 'Personal Trade' setting to true.
        </p>
        <br>
        <br>
        <br> -->
  </div>
</section>
