import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { MatStepper } from '@angular/material/stepper';
import { MockPilots } from './mockPilot';
import { RegisterStore } from 'src/app/store/registerStore/register-store';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoTierModalComponent } from 'src/app/layout/auto-tier-modal/auto-tier-modal.component';


@Component({
  selector: 'app-pilot-list',
  templateUrl: './pilot-list.component.html',
  styleUrls: ['./pilot-list.component.scss']
})
export class PilotListComponent implements OnInit {
  //pilotList: any = MockPilots

  infoStepper: any;
  currStep: any;
  pilotList: any;
  pilotIdx: number;
  order: any;
  showPilot: boolean = false;
  tiers: any = [{}, {}, {}, {}];
  selectedTier: number = -1;
  toggleFullTable: boolean;
  matDialogRef: MatDialogRef<AutoTierModalComponent>;
  lastUpdated: any;

  constructor(private httpClient: HttpClient,
    private _ngZone: NgZone,
    private router:Router,
    private route: ActivatedRoute,
    private activeRouter: ActivatedRoute,
    public regStore: RegisterStore,
    private matDialog: MatDialog) {

   }

  ngOnInit(): void {
//      this.httpClient.get('http://192.168.0.161:8080/pilots').subscribe((res: any) => {
//            this.pilotList = res.pilots;
//          })
     API
       .get('StockPilot', '/pilots', {})
        .then(res => {
          this.lastUpdated = res.lastUpdated;
          this.pilotList = res.pilots;
        }).catch(err => {
          console.log('error fetching pilots', err);
        })
//     this.pilotList = [{'pilotId':'TRADE_BOT_TEST_TWO','pilotName':'Trade Bot Test Two','status':'LIVE','informationStatus':true,'questionnaireStatus':false,'performanceStatus':false,'onboardingStatus':false,'brokerageStatus':true,'legalStatus':true,'legalTimestamp':1637567987752,'assetTypes':['EQUITY'],'positionDurations':['SWING_TRADES'],'optionLevels':['COVERED'],'margin':true,'image':'https:\\/\\/pilot-avatars.s3.amazonaws.com/stockpilotIG.png','message':'asdfasdfasdf','fee':null,'successfulTrades':0,'totalTrades':0,'lastYearPercentageGained':null,'lastMonthPercentageGained':null,'lastDayPercentageGained':null,'riskScore':0,'accountMinimum':null,'monthlyFee':null,'subscriberCount':0,'dateCreated':'2021-11-22T07:59:17.623+00:00','dateUpdated':'2021-11-22T07:59:17.623+00:00'},{'pilotId':'TRADE_BOT_TEST','pilotName':'Trade Bot Test','status':'LIVE','informationStatus':true,'questionnaireStatus':false,'performanceStatus':false,'onboardingStatus':false,'brokerageStatus':true,'legalStatus':true,'legalTimestamp':1637567424953,'assetTypes':['EQUITY'],'positionDurations':['DAY_TRADES'],'optionLevels':['COVERED'],'margin':true,'image':'https:\\/\\/pilot-avatars.s3.amazonaws.com/stockpilotIG.png','message':'asdf','fee':null,'successfulTrades':0,'totalTrades':0,'lastYearPercentageGained':null,'lastMonthPercentageGained':null,'lastDayPercentageGained':null,'riskScore':0,'accountMinimum':null,'monthlyFee':null,'subscriberCount':0,'dateCreated':'2021-11-22T07:49:50.664+00:00','dateUpdated':'2021-11-22T07:49:50.664+00:00'}];
    async () => {
      this.infoStepper = await document.getElementById('infoStepper');
          for(let step of this.infoStepper.steps) {
             step.completed = true;
             step.state = 'done';
          }
    }
    this.currStep="preflight";
  }

  onLearnMore(index: number) {
    this.pilotIdx = index;
    this.showPilot = true;
  }

  onCloseDetail(data) {
    console.log('data');
    this._ngZone.run(()=>{
      this.showPilot = false;
          console.log(data);
          console.log('pilotList');
          this.pilotIdx = -1;
          this.selectedTier = -1;
          this.showPilot = false;
    });
  }

  close() {
    this.showPilot = false;
  }

  closeInfoStep(step) {
    this.currStep = -1;
    this.showPilot = false;
  }

  onSelectStep(step: string) {
    console.log('step');
    if(this.currStep == step) {
      this.currStep = 'asdf';
    } else {
      this.currStep = step;
    }
  }

  onExpandTierDetails(index: number) {
    this.selectedTier = -1;
    if(this.selectedTier == index) {
      this.selectedTier = -1;
      this.pilotIdx = -1;
      this.showPilot = false;
    } else {
      this.selectedTier = index;
      this.pilotIdx = index;
      this.showPilot = true;
    }
  }

  openModal(website: any) {
    this.matDialogRef = this.matDialog.open(AutoTierModalComponent, {
      data: { automationSettings: {}, website: website },
      width:'50%',
      disableClose: true
    });

    this.matDialogRef.afterClosed().subscribe(res => {
      //this.router.navigate(['/register'])
    });
  }

  onSubscribe(index: number, website: string) {
    this.regStore.setRegistrationData({
      pilotId: this.pilotList[index].pilotId
    });
    this.openModal(website);
  }

}
