import { Component, OnInit } from '@angular/core';
import { CommonStore } from './store/commonStore/common-store.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'StockPilot';
  constructor(
    public commonStore:CommonStore

  ) {
    }

  ngOnInit() {

    }

}
