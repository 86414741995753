import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pilot',
  templateUrl: './pilot.component.html',
  styleUrls: ['./pilot.component.css']
})
export class PilotComponent implements OnInit {

  @Input() pilotList: any;
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>(null);

  constructor() { }

  ngOnInit(): void {
    console.log('tierList', this.pilotList);
  }

    async onCloseDetail(data) {
     this.onClose.emit(data);
     console.log('pilot');
    }

}
