<div class="container mt-3 mb-4">
  <div class="row">
    <div class="col-12 mt-4 mt-lg-0">
      <div class="row">
        <div class="col-12">
          <div class="user-dashboard-info-box table-responsive mb-0 bg-card p-4 shadow-sm">
            <div class="container data-table">
              <div>
                <i class="text-secondary" style="margin-top:0.5%;">
                  ** These datasets will begin populating in 1-2 months after post-certification of production data. **
                </i>
                <br>
                <h4>Pilot Performance Data (Time-Weighted Returns)</h4>
                <span class="text-primary">The time-weighted average is used for the comparison of Pilot performance.
              </span>
              </div>
              <br>
              <span class="text-secondary">
              The time-weighted rate of return eliminates the distorting effects caused when individual users deposit or
              withdrawal cash into a subscribed account. It is ideal for the comparison of Pilot fund performance.
            </span>
              <table class="table manage-candidates-top mb-0">
                <thead>
                <tr>
                  <th class="text-primary">Strategy</th>
                  <th class="text-primary">Year</th>
                  <th class="text-primary">Gross</th>
                  <th class="text-primary">Net</th>
                  <th class="text-primary">Benchmark</th>
                  <th class="text-primary">Internal Dispersion</th>
                  <th class="text-primary">Reliability</th>
                  <th class="text-primary">Days</th>
                  <th class="text-primary">Subscribers</th>
                  <th class="text-primary">Assets</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tier of pilotList">
                  <td class="text-left d-flex  align-items-center">
                    <span class="text-secondary">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="container data-table mt-5">
              <div>
                <i class="text-secondary" style="margin-top:0.5%;">
                  ** These datasets will begin populating in 1-2 months after post-certification of production data. **
                </i>
                <br>
                <h4>Subscribed Account Data (Money-Weighted Returns)</h4>
                <span class="text-primary">The money-weighted average is used to represent the typical investment performance as observed across
              actual subscribed accounts.
              </span>
                <br>
                <br>
                <span class="text-secondary">
              The money-weighted average does not account for cash deposits nor withdrawals made by individual
              users. It should be primarily used to assess the results typically realized by members, and NOT for the comparison of Pilot performance.
              </span>
              <table class="table manage-candidates-top mb-0">
                <thead>
                <tr>
                  <th class="text-primary">Strategy</th>
                  <th class="text-primary">Start</th>
                  <th class="text-primary">End</th>
                  <th class="text-primary">Gross</th>
                  <th class="text-primary">Net</th>
                  <th class="text-primary">Benchmark</th>
                  <th class="text-primary">Reliability</th>
                  <th class="text-primary">Days</th>
                  <th class="text-primary">Subscribers</th>
                  <th class="text-primary">Assets</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tier of pilotList">
                  <td class="text-left d-flex  align-items-center">
                    <span class="text-secondary">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-secondary order-1">-</span>
                  </td>
                  <td>
                    <span class="candidate-list-time text-primary order-1">-</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
