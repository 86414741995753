<div *ngIf="true">
  <div class="container justify-content-center">
    <section class="text-center justify-content-center">
      <h4>Publicly-Listed Pilots</h4>
      <span class="text-primary">
    Browse our public Stock Pilot communities below.
  </span>
      <br>
      <br>
      <span class="text-secondary">
    The goal of this page is to assist prospective Stock Pilot members in
    deciding which Pilot to subscribe to. To get started, first review the following key details about the Stock
    Pilot platform and how to best interpret our trade analytics when choosing a Pilot.
  </span>
      <br>
      <br>
      <div>
        <p>
          While every trade taken by a Pilot is tracked by our analytics system, these metrics are not always enough when comparing Pilot performance. A lack
          of trade data for newly registered Pilots, significant political/social/economic events, or just 'the nature of the
          market' may all mean the calculated performance could be misleading when taken at surface level. To help our prospective members better understand the raw trade
          data, we provide two higher-level ranking systems: Pre-Flight Ranking and Platform Ranking. Find out how
          these systems work below:
        </p>
      </div>
      <br>
      <mat-horizontal-stepper [linear]="false" #infoStepper class="bg-dark stepper" id="infoStepper">
        <mat-step>
          <ng-template matStepLabel>
            <div class="overlay" (click)="onSelectStep('preflight')"></div>
            Pre-Flight Ranking
          </ng-template>
          <div *ngIf="currStep == 'preflight'">
            <h6 class="text-primary"></h6>
            <h2>What do pre-flight stars mean?</h2>
            <div>
              <mat-label class="text-primary">The Pre-Flight Ranking System is used to qualify new traders when they first register.</mat-label>
              <br>
              <p class="text-secondary" style="margin-top:0.5%;">
                When new traders register with the Stock Pilot platform, they are prompted to upload a copy of their personal transaction history
                data provided by their brokerage. Pilots who choose to upload their transaction history are ranked by our Pre-Flight Stars system to determine if they are eligible for an immediate ranking on our public listing page. Qualifying Pilots
                are then awarded anywhere from 1 to 3 Pre-Flight Stars, representing how their personal trading history stacks up against their peers.

                The Pre-Flight Ranking System will assign a new Pilot their stars based on their historical rates of return, the associated risk scores,
                the trader's experience level, and any financial certifications they may hold. The outcome is anywhere from 0 to 4 stars.
              </p>
            </div>
            <div>
              <mat-label class="text-primary">The more stars, the better!</mat-label>
              <br>
              <br>
              <i class="text-secondary" style="margin-top:0.5%;">
                ** It may be useful to compare these Pre-Flight rankings when assessing Pilot groups with less than 12 months of trades placed on the platform. **
              </i>
              <br>
              <fieldset>
                <div>
                  <button (click)="closeInfoStep('preflight')" style="margin-right:1%;">
                    Close
                  </button>
                  <button matStepperNext (click)="onSelectStep('system')">
                    Next
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            <div class="overlay" (click)="onSelectStep('system')"></div>
            Platform Ranking
          </ng-template>
          <div *ngIf="currStep == 'system'">
            <h6 class="text-primary"></h6>
            <h2>How is Platform Ranking determined?</h2>
            <div>
              <mat-label class="text-primary">We use the Platform Ranking System to rank each Pilot into one of three classifications.</mat-label>
              <br>
              <p class="text-secondary" style="margin-top:0.5%;">
                All trades placed by Pilots on the platform are considered ranked and tracked by our analytics system. These metrics are used internally to ensure Pilots remain
                within our minimum trade performance requirements over time, as well as publicly by our Platform Ranking System to assist our prospective members in making informed decisions.
              </p>
              <br>
              <p class="text-secondary" style="margin-top:0.5%;">
                These detailed statistics are used by our Platform Ranking System to assign each Pilot a current performance classification, providing a more
                concise way for prospective members better understand what this data may mean for them. A Pilot's performance may be assigned one of
                the following classifications: <b class="text-primary">First Class</b>, <b class="text-primary">Business</b>, <b class="text-primary">Economy</b>.
              </p>
              <p class="text-secondary" style="margin-top:0.5%;">
                The Platform Ranking system will classify a Pilot's performance based solely on trading data collected on the platform, neither the trader's experience level nor any certifications
                will affect this ranking.
              </p>
            </div>
            <div>
              <br>
              <br>
              <i class="text-secondary" style="margin-top:0.5%;">
               **  It is very likely for strategy ROIs to vary from day to week to month, especially during their first twelve months as an adequate
                trade data sample size is established. **
              </i>
              <br>
              <fieldset>
                <div>
                  <button matStepperPrevious (click)="onSelectStep('preflight')" style="margin-right:1%;">
                    Previous
                  </button>
                  <button (click)="closeInfoStep('system')" style="margin-right:1%;">
                    Close
                  </button>
                  <button matStepperNext (click)="onSelectStep('analytics')">
                    Next
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </mat-step>
        <mat-step  label="Pilot Analytics">
          <ng-template matStepLabel>
            <div class="overlay" (click)="onSelectStep('analytics')"></div>
            Pilot Analytics
          </ng-template>
          <div *ngIf="currStep == 'analytics'">
            <h6 class="text-primary"></h6>
            <h2>How is pilot performance tracked on the platform?</h2>
            <div>
              <mat-label class="text-primary">Our pilot analytics system tracks every published trade alert, cancel, and update; as well as actual
                subscriber account execution prices, across all order legs.</mat-label>
              <br>
              <p class="text-secondary" style="margin-top:0.5%;">
                Each time a Pilot publishes a trade alert to their subscribed community, the raw alert values are tracked and used to calculate
                simulated performance metrics based on the Pilot's intentions. These calculated performance metrics, and derivative ranking systems,
                are based loosely on the GIPS investment standards ( <a href="https://www.investopedia.com/terms/g/gips.asp" target="_blank"><u>https://www.investopedia.com/terms/g/gips.asp</u></a> ). These metrics are useful in a relative comparison of Pilots. To
                help provide a more realistic expectation of the performance realized by our actual subscribed accounts, we additionally track actual fill prices
                on subscribed accounts as executed by their brokerage. This will be soon used to calculated money-weighted returns across real subscriber accounts.
              </p>
              <br>
              <p class="text-secondary" style="margin-top:0.5%;">

              </p>
            </div>
            <br>
            <div>
              <fieldset>
                <div>
                  <button matStepperPrevious (click)="onSelectStep('system')" style="margin-right:1%;">
                    Previous
                  </button>
                  <button (click)="closeInfoStep('analytics')" style="margin-right:1%;">
                    Close
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </section>
    <i class="text-secondary" style="margin-top:0.5%;">
      ** The calculations for pre-flight ranking, platform ranking, and strategy returns will be released shortly. We are currently finalizing some calculations and pending the
      collection of 1-2 months of production data for post-certification. You will see hyphens in their place for now. Some of the content seen here is liable to change during this time. **
    </i>
    <br>
    <br>
    <i class="text-secondary" style="margin-top:0.5%;">
      ** Currently these data are updated on a daily schedule. Last updated: {{lastUpdated}}Z **
    </i>
    <br>
    <br>
    <i class="text-secondary" style="margin-top:0.5%;">
      ** These data may be best viewed on a desktop or on mobile in landscape mode at this time. **
    </i>
  </div>
  <br>
  <div class="container mt-3 mb-4">
    <div class="row">
      <div class="col-12 mt-4 mt-lg-0">
        <div class="row">
          <div class="col-12">
            <div class="user-dashboard-info-box table-responsive mb-0 bg-card p-4 shadow-sm">
              <div class="flex-nowrap d-table" id="accordion">
                <div class="card">
                  <div class="card-header">
                    <a class="card-link">
                      <div class="row flex-nowrap">
                        <div class="col first-column">
                          <p class="text-primary">Pilot</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Pre-Flight Ranking</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Platform Ranking</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Account Sizes</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Strategies</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Monthly Fees</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Total Assets</p>
                        </div>
                        <div class="col">
                          <p class="text-primary">Created</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="show">
                    <div class="card-body detail-box" *ngFor="let pilot of pilotList; let i=index">
                      <a class="card-link">
                        <div class="row pilot-item flex-nowrap" >
                          <div class="col first-column text-left d-flex align-items-center img-col">
                            <img  class="img-fluid" [src]="pilot.image != null ? pilot.image : 'assets/images/logo.gif'"
                                 [alt]="pilot.pilotName">
                            <div class="">
                            <span class="text-primary">{{pilot.pilotName}}
                                <br>
                              <p class="text-secondary pilot-id">{{pilot?.pilotStats?.subscribers}} Subscribers</p>
                              </span>
                            </div>
                          </div>
                          <div class="col">
                            <span class="candidate-list-time order-1"><i class="fa fa-star" *ngFor="let star of pilot?.pilotStats?.preFlightStars"></i><span *ngIf="pilot?.pilotStats?.preFlightStars.length <= 0" class="text-primary">-</span></span>
                          </div>
                          <div class="col">
                            <span class="candidate-list-time text-primary order-1">{{pilot?.pilotStats?.platformRanking != '-' ? pilot.pilotStats.platformRanking : '-'}}</span>
                          </div>
                          <div class="col">
                            <span *ngIf="pilot?.pilotStats?.accountSizes[0] != pilot?.pilotStats?.accountSizes[1]" class="candidate-list-time text-secondary order-1">${{pilot?.pilotStats?.accountSizes[0]}} - ${{pilot?.pilotStats?.accountSizes[1]}}+</span>
                            <span *ngIf="pilot?.pilotStats?.accountSizes[0] == pilot?.pilotStats?.accountSizes[1]" class="candidate-list-time text-secondary order-1">${{pilot?.pilotStats?.accountSizes[0]}}+</span>
                          </div>
                          <div class="col">
                            <span class="candidate-list-time text-secondary order-1">{{pilot?.pilotStats?.accountTiers}} Strategies</span>
                          </div>
                          <div class="col">
                            <span *ngIf="pilot?.pilotStats?.monthlyFees[0] != pilot?.pilotStats?.monthlyFees[pilot?.pilotStats?.monthlyFees.length-1]" class="candidate-list-time text-secondary order-1">${{pilot?.pilotStats?.monthlyFees[0]}} - ${{pilot?.pilotStats?.monthlyFees[1]}}</span>
                            <span *ngIf="pilot?.pilotStats?.monthlyFees[0] == pilot?.pilotStats?.monthlyFees[pilot?.pilotStats?.monthlyFees.length-1]" class="candidate-list-time text-secondary order-1">${{pilot?.pilotStats?.monthlyFees[0]}}</span>
                          </div>
                          <div class="col">
                            <span class="candidate-list-time text-secondary order-1">{{pilot?.pilotStats?.pilotAssets | currency}}</span>
                          </div>
                          <div class="col">
                            <span class="candidate-list-time text-secondary order-1">{{pilot?.dateCreated | date}}</span>
                          </div>
                          <hr>
                        </div>
                        <ng-container *ngIf="selectedTier == -1">
                          <div>
                            <div class="line justify-content-center" style="width:80%;margin-left:10%;"></div>
                            <div class="row flex-nowrap">
                              <div class="col-5 pilot-message">
                                <div class="row">
                                  <div class="col">
                                    <i>{{pilot.message}}</i>
                                    <br>
                                    <br>
                                    <button class="btn btn-primary" (click)="onSubscribe(i, pilot.website)">Subscribe</button>
                                  </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="float-right" style="display: block;" (click)="onExpandTierDetails(i)">
                                  <i *ngIf="!toggleFullTable" class="fa fa-expand"></i>
                                  <i *ngIf="!toggleFullTable" class="fa fa-compress"></i>
                                </div>
                                <div class="container detail-box">
                                  <div class="row flex-nowrap">
                                    <div class="col">
                                      <p class="text-primary">Strategy</p>
                                    </div>
                                    <div class="col">
                                      <p class="text-primary">ROI (TWR)</p>
                                    </div>
                                    <div class="col">
                                      <p class="text-primary">Custom Benchmark</p>
                                    </div>
                                    <div class="col">
                                      <p class="text-primary">Risk Ratio</p>
                                    </div>
                                    <div class="col">
                                      <p class="text-primary">Data Reliability</p>
                                    </div>
                                  </div>
                                  <div class="row justify-content-center mt-3" *ngFor="let strategies of pilot?.pilotStats?.tierStats; let i = index;">
                                    <div class="col">
                                      <div *ngIf="true">
                                    <span class="text-primary">{{strategies.tierName}}
                                      <div *ngIf="strategies.tierRange[0] == strategies.tierRange[strategies.tierRange.length-1]" class="text-muted pilot-id">${{strategies.tierRange[0]}} - ${{strategies.tierRange[1]}}</div>
                                      <div *ngIf="strategies.tierRange[0] != strategies.tierRange[strategies.tierRange.length-1]" class="text-muted pilot-id">${{strategies.tierRange[0]}}+</div>
                                    </span>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <p *ngIf="strategies?.overallTwrROI != 0">{{strategies?.overallTwrROI | percent}}</p>
                                      <p *ngIf="strategies?.overallTwrROI == 0">- %</p>
                                    </div>
                                    <div class="col">
                                      <p *ngIf="strategies?.overallCustomBenchmark != 0">{{strategies?.overallCustomBenchmark | percent}}</p>
                                      <p *ngIf="strategies?.overallCustomBenchmark == 0">- %</p>
                                    </div>
                                    <div class="col">
                                      <p *ngIf="strategies?.overallRiskRatio != 0">{{strategies?.overallRiskRatio}}</p>
                                      <p *ngIf="strategies?.overallRiskRatio == 0">- %</p>
                                    </div>
                                    <div class="col">
                                      <p>{{strategies?.overallRiskRatio == 0 ? 'Low' : ''}}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="selectedTier == i && showPilot" class="justify-content-center">
                          <div class="float-right"><i class="fa fa-times" role="button" (click)="onCloseDetail('close')"></i></div>
                          <app-pilot *ngIf="selectedTier == i && showPilot" style="height:50vh; " [pilotList]="pilotList[i].pilotStats?.tierStats" (onCloseDetail)="onCloseDetail('close')"></app-pilot>
                        </ng-container>
                      </a>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
