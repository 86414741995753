<div>
  <div class="float-right"><i class="fa fa-times" role="button" (click)="onClose()"></i></div>
  <h2 mat-dialog-title>Click continue to learn more on the Pilot's website.</h2>
  <div mat-dialog-content>
    <p class="d-block text-output">
      Subscriptions are currently managed through the Pilot's website. Please click continue to be redirected to the
      Pilot's website, where you can learn more about their trading strategies and how to subscribe to their Stock Pilot auto-trading package.
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onContinue()">Continue</button>
  </div>
</div>
