<div style="width: 75%;" class="center">
  <section id="hero-sec" class="container-space section-space">
    <div class="row">
      <div class="col-sm-6 ">
        <br>
        <h4 class="text-center">{{blog.title}}</h4>
        <br>
        <p class="text-center hook">
          Asset classes have expected pattern of correlation with one another. In a previous blog, these relationships were explored. When the price of commodities rise, the value of currency decreases, causing inflation. When interest rates are increased, the value of currency increases, lowering inflation. However, when the returns of one asset class does not follow this pattern, decoupling occurs.
        </p>
        <p>
          Figure 2. Federal Funds Effective Rate (FEDFUNDS) FRED Economic Data
          Understanding the relationship of assets, investors can create diversified portfolios with investments that are not associated with one another. This strategy mitigates the loss when one asset value falls, affecting the other assets less.
        </p>
        <p>
          Furthermore, assets in the same industry tend to have a high positive correlated. A fall in the stock price of one company may indicate the fall of the stock prices of other companies in the same industry. Yet, in the case of decoupling, the price of investments or commodities in the same industry or similar industries move in opposite directions. An example is when gold prices fall but mining shares increase, indicating that mining companies are decoupled from the price of gold.
        </p>
      </div>
      <div class="col-sm-5 offset-md-1">
        <img style="width: 75%;" src="assets/images/blog/equity-bond-decouple-vg.png">
      </div>
    </div>
    <br>
  </section>
  <section id="hero-sec" class="container-space section-space">
    <div class="row">
      <div class="col-sm-5 offset-md-1">
        <img style="width: 75%;" src="assets/images/blog/equity-bond-decouple-fred.png">
      </div>
      <div class="col-sm-6 ">
        <p>
          Interest rates and bond prices have an inverse relationship. Bonds and stocks have an inverse relationship but are both similarly affected by interest and inflation rates. In the past year, bond prices are generally down. However, overall stock prices are only slightly down. This trend indicates that bond prices and stock prices are less inversely correlated, meaning that bonds and stocks have slightly decoupled compared to before.
        </p>
        <p>
          Investors could view decoupling as an investment opportunity if they believe that the normal pattern of correlation will return.
        </p>
        <br>
        <h6><i>Sources:</i></h6>
        <a href="https://www.investopedia.com/terms/d/decoupling.asp">https://www.investopedia.com/terms/d/decoupling.asp</a>
        <br>
        <a href="https://www.wallstreetmojo.com/decoupling/">https://www.wallstreetmojo.com/decoupling/</a>
        <br>
        <a href="https://www.investopedia.com/articles/fundamental-analysis/09/intermarket-relations.asp">https://www.investopedia.com/articles/fundamental-analysis/09/intermarket-relations.asp</a>
        <br>
        <a href="https://www.ig.com/en/trading-strategies/what_s-the-relationship-between-stocks-and-bonds--210702">https://www.ig.com/en/trading-strategies/what_s-the-relationship-between-stocks-and-bonds--210702</a>
      </div>
    </div>
    <br>
  </section>
</div>
