const awsmobile = {
    "aws_project_region": "us-east-1",
// PROD
    "aws_cognito_identity_pool_id": "us-east-1:18293f21-2617-4bea-ab13-bf39106b9ed3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_apWFdfYUQ",
    "aws_user_pools_web_client_id": "55o7hli3itu6k5b9joi1ojitsm",
// DEV
//    "aws_cognito_identity_pool_id": "us-east-1:0726db32-3b65-4dc3-beb9-a009a1db8129",
//    "aws_cognito_region": "us-east-1",
//    "aws_user_pools_id": "us-east-1_Tlfnaoz73",
//    "aws_user_pools_web_client_id": "1bes2j245s7rubip93rk1jm5gh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "API": {
        "endpoints": [
            {
                "name": "StockPilot",
                "endpoint": 'https://v2.io.stockpilot.app' //https://v2.io.stockpilot.app" //https://v2.io.stockpilot.app" //"https://oxp5xadk2e.execute-api.us-east-1.amazonaws.com/dev" //http://192.168.0.161:8080" //https://7hiy3yco60.execute-api.us-east-1.amazonaws.com/prod" //https://oxp5xadk2e.execute-api.us-east-1.amazonaws.com/dev"
            },
            {
                "name": "localhost",
                "endpoint": "http://localhost:8080"
            }
        ]
    }
};




export default awsmobile;
