<section class="card-box spacing">
    <nav class="navbar navbar-expand-md navbar-dark">
      <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-icon-to-home' data-stockpilot-analytics-attrs='attr1:icon_to_home_clicked'routerLink="/home" routerLinkActive="active"><img src="assets/images/SPLogo.svg" style="margin-right:2%;"></a><span style="margin-left:2%;"><i *ngIf="($groupName | async)=='RIGHTCHOICE'" class="fa fa-times"></i></span><span style="margin-left:2%;"><img *ngIf="($groupName | async)=='RIGHTCHOICE'" style="margin-left:2%;" src="assets/images/right_choice_logo-dark.png"></span>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavbar" *ngIf="role">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <!-- <a class="nav-link" href="#">Link</a> -->
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-login' data-stockpilot-analytics-attrs='attr1:login_clicked' *ngIf="!currentUser" class=" text-secondary nav-link login mr-4 font-size-a" routerLink="/login" routerLinkActive="active">Login</a>
          </li>
          <li class="nav-item">
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-get-started' data-stockpilot-analytics-attrs='attr1:get_started_clicked' *ngIf="!currentUser" class="nav-link font-size-a text-secondary" routerLink="signup" routerLinkActive="active">Get started</a>
            <!-- <a class="nav-link" href="#">Link</a> -->
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="#">Link</a> -->
            <div data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-account-username' data-stockpilot-analytics-attrs='attr1:account_username_clicked' *ngIf="currentUser" class="nav-link  mr-4 font-size-a">Welcome back, <a class="welcome-a ml-2 font-size-a text-secondary" routerLink="/account" routerLinkActive="active">{{currentUser}}</a>! <i title="How It Works" role="button" [routerLink]="['/account']" class="fa fa-info-circle"></i></div>
          </li>
          <li class="nav-item">
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-dashboard' data-stockpilot-analytics-attrs='attr1:dashboard_clicked' class="nav-link text-primary mr-4 font-size-a" routerLink="/FAQ" routerLinkActive="active">FAQ</a>
            <!-- <a class="nav-link" href="#">Link</a> -->
          </li>
          <li class="nav-item" *ngIf="role && role === 'Subscriber'">
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-dashboard' data-stockpilot-analytics-attrs='attr1:dashboard_clicked' *ngIf="currentUser"  class="nav-link text-primary mr-4 font-size-a" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
            <!-- <a class="nav-link" href="#">Link</a> -->
          </li>
          <li class="nav-item" *ngIf="role && role === 'Pilots'">
            <a  data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-dashboard' data-stockpilot-analytics-attrs='attr1:dashboard_clicked' *ngIf="currentUser"  class="nav-link text-primary mr-4 font-size-a" routerLink="/pilot" routerLinkActive="active">Dashboard</a>
          </li>
          <li class="nav-item">
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-sign-out' data-stockpilot-analytics-attrs='attr1:sign_out_clicked' *ngIf="currentUser"  class="nav-link font-size-a text-secondary" (click)="signOut()">Sign out</a>
            <!-- <a class="nav-link" href="#">Link</a> -->
          </li>
          <li class="nav-item">
            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-dashboard' data-stockpilot-analytics-attrs='attr1:dashboard_clicked' class="nav-link text-primary mr-4 font-size-a" routerLink="/pilots" routerLinkActive="active">Pilots</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a data-stockpilot-analytics-on='click' data-stockpilot-analytics-name='click-dashboard' data-stockpilot-analytics-attrs='attr1:dashboard_clicked' class="nav-link text-primary mr-4 font-size-a" routerLink="/blog" routerLinkActive="active">Blog</a>-->
<!--          </li>-->
        </ul>
      </div>
    </nav>

</section>
