// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey:"pk_live_51IVNkvFem72EoOyB7yRtWVg4Deic9KqOnzozhZADfG3Fhh3O1SLv0u4Pfvf4OwXTyHIlOkLb2LB6BurcFnjkLOJv000ngJTfvK",
  testStripeKey:"pk_live_51IVNkvFem72EoOyB7yRtWVg4Deic9KqOnzozhZADfG3Fhh3O1SLv0u4Pfvf4OwXTyHIlOkLb2LB6BurcFnjkLOJv000ngJTfvK",

  calendlyUrl: 'https://calendly.com/jacksonocarra',

  sp_api: 'https://v2.io.stockpilot.app' //'http://localhost:8080' //'https://v2.io.stockpilot.app' //https://v2.io.stockpilot.app'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
