import { Component, OnInit,  AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Auth } from '@aws-amplify/auth';
// import { Hub } from '@aws-amplify/core';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  signedIn: boolean = false;
  username: string;
  currentUser: string = '';

    constructor(
      private authenticationService: AuthenticationService,
      private router: Router
    ) {
      this.authenticationService._authState.subscribe( value => {
        this.signedIn = value.isLoggedIn;

      })
    }

    ngOnInit() {
      Auth.currentAuthenticatedUser().then((data) => {
          this.currentUser = data.username;
      });
    }

    ngAfterViewInit() {
      this.authenticationService._authState.subscribe((name) => {
            if(name.username) {
              this.currentUser = name.username;
              // location.reload();
            } else {
              this.currentUser = '';
            }
          });
    }


    signOut(): void {
      this.router.navigate['home'];
      this.authenticationService.signOut();
    }
}
