import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './alert/alert.component';
/* Add Amplify imports */
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import Amplify from '@aws-amplify/core';
import awsmobile from '../aws-exports';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import { HeaderComponent } from './layout/header/header.component';
import { ContactComponent } from './contact/contact.component';
import { MaterialModules } from './material.modules';
import { FooterComponent } from './layout/footer/footer.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { PilotListComponent } from './layout/pilot-list/pilot-list.component';
import { FaqComponent } from './layout/faq/faq.component';
import { PilotComponent } from './layout/pilot/pilot.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import { AutoTierModalComponent } from './layout/auto-tier-modal/auto-tier-modal.component';
import { ConfirmTradeComponent } from './layout/confirm-trade/confirm-trade.component';
import { BlogComponent } from './layout/blog/blog.component';
import { BlogCardComponent } from './layout/blog-card/blog-card.component';


@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    HeaderComponent,
    ContactComponent,
    FooterComponent,
    LoaderComponent,
    PilotListComponent,
    FaqComponent,
    PilotComponent,
    AutoTierModalComponent,
    ConfirmTradeComponent,
    BlogComponent,
    BlogCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AmplifyAuthenticatorModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatIconModule,
    A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MaterialModules,
        OverlayModule,
        PortalModule,
        ScrollingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
