import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegisterStore {
  registrationData: any;

  constructor() {
    if (window.localStorage['groupAndAccount']) {
      let user = JSON.parse(localStorage["groupAndAccount"]);
      this.registrationData = Object.assign({}, user);
    }
    () => {
      if (this.registrationData) {
        window.localStorage.setItem('groupAndAccount', JSON.stringify(this.registrationData))
      }
      if (window.localStorage['groupAndAccount']) {
        let user = JSON.parse(localStorage["groupAndAccount"]);
        this.registrationData = Object.assign({}, user);
      }
    }
  }

  setRegistrationData(userData) {
    if(localStorage["groupAndAccount"]){
      let user = JSON.parse(localStorage["groupAndAccount"]);
      this.registrationData = Object.assign({}, user);
    }
    this.registrationData = {...this.registrationData, ...userData};
    localStorage.setItem('groupAndAccount', JSON.stringify(this.registrationData))
  }

  setNewAnalyst(status) {

  }

  getRegData() {
    return this.registrationData;
  }

  reset() {
    this.registrationData = null;
    localStorage.removeItem('groupAndAccount');
  }
}
