import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { RegisterStore } from '../store/registerStore/register-store';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private regStore: RegisterStore
    ) { }

    newAnalyst: any = false;

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            Auth.currentSession()
                .then(async (res:any) => {
                    let accessToken = res.getIdToken();
                    let role = accessToken.payload['cognito:groups'] && accessToken.payload['cognito:groups'][0] == 'Pilots' ||
                   this.regStore?.registrationData?.pilot  ? 'Pilots' : 'Subscriber';
                    let url = role === 'Pilots' ? '/analysts/' + accessToken.payload.sub + '/settings' : '/users/' + accessToken.payload.sub + '/settings';
                    let data: any | null;
                    await API
                        .get('StockPilot', url,
                            { headers: { Authorization: 'Bearer ' + accessToken.getJwtToken() } })
                            .then(res => {
                              data = res;
                            });
                    if(!data.pilotId) {
                      this.newAnalyst = true;
                      await API.get('StockPilot', '/analysts/' + accessToken.payload.sub + '/settings',
                            { headers: { Authorization: 'Bearer ' + accessToken.getJwtToken() } })
                            .then(resp => { data = resp })
                            .catch(err => { console.error(err) });
                    }
                    if(role == 'Subscriber' && data.managementStatus == null) {
                      role = 'Pilots';
                    }

                    let status = role == 'Pilots' ? data.status : data.managementStatus;

                    let regData = this.extractData(data);
//                     regData['pilot'] = role === 'Pilots' ? true : null;
//                     if(this.newAnalyst) {
//                       regData['analystStatus'] = status;
//                     }
//                     this.regStore.setRegistrationData(regData);

                    if (status == 'ONBOARDING' || status=='PENDING' || status=='INITIAL' || status=='APPROVED') {
                        this.router.navigate(['/register']);
                        if (!localStorage.getItem('groupAndAccount')) {
                          let regData = this.extractData(data);
                          regData['pilot'] = role === 'Pilots' ? true : null;
                          if(this.newAnalyst) {
                            regData['analystStatus'] = status;
                          }
                          this.regStore.setRegistrationData(regData);
                        }
                    }
                    else if (!status && data.clientId) {
                        this.router.navigate(['/signup']);
                    }

                   resolve(status == 'OK' || status == 'FLAGGED' || status == 'OFFBOARDING' || status=='ONBOARDING');
                   //resolve(true);
                })
                .catch(err => {
                    // TODO success scenario
                    // this.router.navigate(['/login'])
                    // testing scenario
                    console.log(err);
                    this.router.navigate(['/signup'])

                    resolve(false);
                });
        })

    }


    extractData(data) {
        let tempData = {
            accessCode: data.accessCode,
            pilotId: data.pilotId,
            pilotName: data.pilotName,
            channelId: data.channelId,
            accountId: data.accountId,
            brokerageId: data.brokerageId,
            // brokerageStatus: data.brokerageStatus,
            legalAgreement: data.legalAgreement,
            subscriptionStatus: data.subscriptionStatus,
            isOwner: data.isOwner,
            status: data.status
        }
        return tempData;
    }
}
