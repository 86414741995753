import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { PilotListComponent } from './layout/pilot-list/pilot-list.component';
import { FaqComponent } from './layout/faq/faq.component';
import { ConfirmTradeComponent } from './layout/confirm-trade/confirm-trade.component';
import { AuthGuard } from './security/auth.guard';
import { BlogComponent } from './layout/blog/blog.component';

const routes: Routes = [

  {
    path: '', loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]
  },
  {
    path: 'pilot',
    loadChildren: () => import('./pilot/pilot.module').then(m => m.PilotModule), canActivate: [AuthGuard]
  },
  { path: 'contact', component: ContactComponent },
  { path: 'pilots', component: PilotListComponent },
  //{ path: 'blog', component: BlogComponent },
  { path: 'authorize-trade', component: ConfirmTradeComponent },
  { path: 'FAQ', component: FaqComponent },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m=>m.AccountModule), canActivate: [AuthGuard]},
  { path: '**', redirectTo: 'home' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
