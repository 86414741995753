


<div *ngIf="true">
  <div class="container justify-content-center">
    <section class="text-center justify-content-center">
      <h4>Alternative Investments Blog</h4>
      <span class="text-primary">
        Browse our selection of financial, technology, and current event based blogs below!
      </span>
      <br>
      <br>
      <p>
        While every trade taken by a Pilot is tracked by our analytics system, these metrics are not always enough when comparing Pilot performance. A lack
        of trade data for newly registered Pilots, significant political/social/economic events, or just 'the nature of the
        market' may all mean the calculated performance could be misleading when taken at surface level. To help our prospective members better understand the raw trade
        data, we provide two higher-level ranking systems: Pre-Flight Ranking and Platform Ranking. Find out how
        these systems work below:
      </p>
    </section>
    <section class="cards-wrapper" *ngIf="!this.blog">
      <div class="card-grid-space" *ngFor="let blog of blogs; let i = index">
        <div class="num" style="color: #fafafa">{{blog.num}}</div>
        <a class="card" (click)="setBlog(i)" [style]="blog.img">
          <div>
            <h1>{{blog.title}}</h1>
            <p>{{blog.snippet}}</p>
            <div class="date">{{blog.date}}</div>
            <div class="tags">
              <div class="tag">{{blog.tag}}</div>
            </div>
          </div>
        </a>
      </div>
    </section>
    <section *ngIf="this.blog">
      <div *ngIf="this.blog">
        <div style="margin-left: 12.5%;" class="mb-2">
          <span><a href="/blog" class="text-primary">Blog</a> <span class="text-primary"> / </span> <a href="/blog" class="text-primary">Finance</a> <span class="text-primary"> / </span> <a class="text-primary">Decoupling in Financial Markets</a></span>
        </div>
        <app-blog-card [blog]="this.blog"></app-blog-card>
      </div>
    </section>
    <section >
      <div class="row ">
        <div class="col-sm-6 pr-4" style="border-top:1px solid #383636">
          <h4 class="text-center">Want to learn more about alternative investments and how you may benefit?</h4>
          <div >
            <p class="text-center"></p>
            <div class="">
              <form [formGroup]="subscriptionForm">

                <div class="form-group d-flex justify-content-center align-items-center">
                  <div class="mr-3">
                    <span class="text-primary">Subscribe to our blog today!</span>
                  </div>
                  <div>
                    <div>
                      <input type="text" name="email" formControlName="email" class="form-control" id="email" placeholder="Email">
                    </div>
                    <div *ngIf="false" class="font-14 text-danger">
                      <div *ngIf="false">Email Is Required</div>
                      <div *ngIf="false">Please Provide a Valid Email</div>
                    </div>
                  </div>
                  <div  class="ml-3">
                    <div class="">
                      <button   class="btn btn-primary">Send</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <h4 class="text-center">Want to browse our automated alternative investment strategies?</h4>
          <div class="text-center">
            <div class="float-center">
              <div class="float-center">
                <button class="btn btn-primary" [routerLink]="['/pilots']">Pilots</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
  <br>
</div>
