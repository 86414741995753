import { Component, OnInit,  AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterStore } from 'src/app/store/registerStore/register-store';
import { Auth } from '@aws-amplify/auth';
import { Observable, of } from 'rxjs';
// import { Hub } from '@aws-amplify/core';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  signedIn: boolean = false;
  username: string;
  currentUser: string = '';
  role: string = '';
  $groupName: Observable<string>;

    constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private _ngZone: NgZone,
      public regStore: RegisterStore
    ) {
    }

    ngOnInit() {
    this.authenticationService._authState.subscribe(async (name) => {
                if(name.username) {
                  this.currentUser = name?.username;
                  this.role = await name?.role;
                  // location.reload();
                } else {
                  this.currentUser = '';
                  this.role = 'Public';
                }
                if(this.currentUser == null || '' || this.role == null || this.role == '') {
                  Auth.currentSession().then(res => {
                      let accessToken = res.getIdToken();
                      this.currentUser = accessToken.payload['cognito:username'];
                      this.role = accessToken.payload['cognito:groups'] && accessToken.payload['cognito:groups'][0] === 'Pilots' ? 'Pilots' : 'Subscriber';
                    }).catch(error => {
                      console.log(error);
                      this.currentUser = '';
                      this.role = 'Public';
                    });
                }
                this.$groupName = of(this.regStore?.getRegData()?.pilotId);
              });
    }

    ngAfterViewInit() {
//       this._ngZone.run(async ()=>{
//         Auth.currentSession().then(async res => {
//           let accessToken = res.getIdToken();
//           this.role = accessToken.payload['cognito:groups'] && accessToken.payload['cognito:groups'][0] === 'Pilots' ? 'Pilots' : 'Subscriber';
//         }).catch(error => {
//           console.log(error);
//         });
//       });
    }

    signOut(): void {
      this.$groupName = of('');
      this.authenticationService.signOut();
      setTimeout(() => {
        window.location.href = window.location.hostname;
      }, 1000);

    }
}
