import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import API from '@aws-amplify/api';

@Component({
  selector: 'app-confirm-trade',
  templateUrl: './confirm-trade.component.html',
  styleUrls: ['./confirm-trade.component.css']
})
export class ConfirmTradeComponent implements OnInit {

  pilotId: any;
  tradeId: any;
  tradeResponse: any;

  constructor(private route: ActivatedRoute,
      private router: Router,
      private activeRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.pilotId = this.route.snapshot.queryParams['pilotId'];
    this.tradeId = this.route.snapshot.queryParams['tradeId'];
    if(this.pilotId != null && this.tradeId != null) {
      API
           .get('StockPilot', '/pilots/' + this.pilotId + '/trades/' + this.tradeId + '/authorize-trade', {})
            .then(res => {
              this.tradeResponse = res;
            }).catch(err => {
              this.tradeResponse = { status: 'ERROR', message: 'Please contact your system administrator.' };
              console.log('error confirming trade', err);
            })
    } else {
      this.tradeResponse = { status: 'ERROR', message: 'Please contact your system administrator.' };
    }
  }

}
