import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CommonStore {
  loader: boolean = false;

  constructor() { }
  loaderStart() {
    if (this.loader == true) {
      this.loader = false;
    }
    setTimeout(() => {
      this.loader = true;
    }, 0);
  }
  get loaderState(){
    return this.loader;
  }
  loaderEnd() {
    setTimeout(() => {
      if (this.loader)
        this.loader = false;
    }, 2000);
  }
}
