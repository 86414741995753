export class AuthState {
    isLoggedIn: boolean;
    username: string | null;
    id: string | null;
    email: string | null;
    role: string | null;

    constructor(){
        this.isLoggedIn= false;
        this.username= null;
        this.id= null;
        this.email= null;
        this.role = null;
    }
  }
