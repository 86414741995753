import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-auto-tier-modal',
  templateUrl: './auto-tier-modal.component.html',
  styleUrls: ['./auto-tier-modal.component.css']
})
export class AutoTierModalComponent {

  automationSettings: any[];
  channels: string;
  website: any;

  constructor(
    private _mdr: MatDialogRef<AutoTierModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.website = data?.website;
      this.automationSettings = data.automationSettings ? data.automationSettings : [];
      if(this.automationSettings.length > 0) {
        this.channels = this.automationSettings[0].channelId;
        for(let i = 1; i < this.automationSettings.length; i++) {
          this.channels += ', ' + this.automationSettings[i].channelId;
        }
      }

    }

    onContinue() {
      window.location = this.website;
    }

    onClose(){
      this._mdr.close();
    }


}
